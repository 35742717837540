import {ApplicationRef, Injectable} from '@angular/core';
import {BehaviorSubject}            from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ThemeService
{
    public readonly theme: BehaviorSubject<'light-theme'|'dark-theme'> = new BehaviorSubject<'light-theme'|'dark-theme'>('light-theme');

    constructor(private applicationRef: ApplicationRef)
    {
        const darkModeOn: boolean = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

        if (darkModeOn) {
            this.theme.next('dark-theme');
        }

        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event: MediaQueryListEvent) => {
            const turnOn: boolean = event.matches;

            this.theme.next(turnOn ? 'dark-theme' : 'light-theme');

            this.applicationRef.tick();
        });
    }

    public toggleTheme(): void
    {
        this.theme.next(this.theme.value === 'dark-theme' ? 'light-theme' : 'dark-theme');
    }
}
