import {Component}   from '@angular/core';
import Hotjar        from '@hotjar/browser';
import {environment} from '@src/environments/environment';

@Component({
    selector: 'app-root',
    template: `
        <router-outlet></router-outlet>
        <tour-step-template></tour-step-template>
        `,
})
export class AppComponent
{
    public constructor()
    {
        if (environment.production) {
            Hotjar.init(environment.hotjar.siteId, environment.hotjar.version);
        }
    }
}
