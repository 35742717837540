import {CommonModule, DatePipe, NgOptimizedImage} from '@angular/common';
import {NgModule}                                 from '@angular/core';
import {MatButtonModule}                   from '@angular/material/button';
import {MatIconModule}                     from '@angular/material/icon';
import {MatListModule}                     from '@angular/material/list';
import {MatMenuModule}                     from '@angular/material/menu';
import {MatSidenavModule}                  from '@angular/material/sidenav';
import {MatToolbarModule}                  from '@angular/material/toolbar';
import {RouterModule}                      from '@angular/router';
import {PurchaseCreditsComponent}          from '@src/app/admin/settings/components/purchase-credits/purchase-credits.component';
import {OrganisationInitialLoginComponent} from '@src/app/interim/organisation-initial-login/organisation-initial-login.component';
import {AdminRoutingModule}                from '@src/app/layout/admin/admin-routing.module';
import {AdminComponent}                    from '@src/app/layout/admin/admin.component';
import {AdminFooterComponent}              from '@src/app/layout/admin/components/footer/admin-footer.component';
import {PageHeaderComponent}               from '@src/app/layout/admin/components/page-header/page-header.component';
import {AssingeesPanelComponent}           from '@src/app/shared/components/panels/assignees/assingees-panel.component';
import {FinancialsPanelComponent}          from '@src/app/shared/components/panels/financials/financials-panel.component';
import {CanDirective}                      from '@src/app/shared/directives/can.directive';
import {CantDirective}                     from '@src/app/shared/directives/cant.directive';
import {HasRoleDirective}                  from '@src/app/shared/directives/has-role.directive';
import {SharedModule}                      from '@src/app/shared/shared.module';
import {TourMatMenuModule}                 from 'ngx-ui-tour-md-menu';

@NgModule({
    imports: [
        CommonModule,
        AdminRoutingModule,
        MatSidenavModule,
        MatListModule,
        MatToolbarModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        RouterModule,
        SharedModule,
        TourMatMenuModule,
        NgOptimizedImage,
    ],
    declarations: [
        AdminComponent,
        AdminFooterComponent,
        PageHeaderComponent,
        CanDirective,
        CantDirective,
        HasRoleDirective,
        OrganisationInitialLoginComponent,
        AssingeesPanelComponent,
        FinancialsPanelComponent,
        PurchaseCreditsComponent
    ],
    providers: [DatePipe],
    exports: [
        RouterModule,
        SharedModule,
        CanDirective,
        CantDirective,
        HasRoleDirective,
        PageHeaderComponent,
        OrganisationInitialLoginComponent,
        AssingeesPanelComponent,
        FinancialsPanelComponent,
        PurchaseCreditsComponent
    ],
})
export class AdminModule
{
}
