import {NgModule}                from '@angular/core';
import {ForgotPasswordComponent} from '@src/app/core/components/forgot-password/forgot-password.component';
import {LoginComponent}          from '@src/app/core/components/login/login.component';
import {ResetPasswordComponent}  from '@src/app/core/components/reset-password/reset-password.component';
import {SharedModule}            from '@src/app/shared/shared.module';
import { CreatePasswordComponent } from './components/create-password/create-password.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import {CoreRoutingModule}       from './core-routing.module';
import { NgOtpInputModule } from  'ng-otp-input';
import { OtpVerificationComponent } from './components/otp-verification/otp-verification.component';

@NgModule({
    imports: [
        CoreRoutingModule,
        SharedModule,
        NgOtpInputModule
    ],
    declarations: [
        LoginComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        SignUpComponent,
        CreatePasswordComponent,
        OtpVerificationComponent
    ],
    exports: [
        LoginComponent,
        SharedModule
    ]
})
export class CoreModule
{
}
