<div class="container-fluid">
    <div class="row">
        <form class="col-12 form" [formGroup]="productsFilterForm" (submit)="loadProducts()">
            <mat-expansion-panel style="margin: 1em 0;">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon>filter_alt</mat-icon>
                        Filter
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row">
                    <div class="col-md-3">
                        <mat-form-field>
                            <mat-label>Name</mat-label>
                            <input matInput type="text" formControlName="name" />
                        </mat-form-field>
                    </div>

                    <div class="col-md-3">
                        <mat-form-field>
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="status_id">
                                <mat-option [value]="1">Active</mat-option>
                                <mat-option [value]="2">In Active</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-3">
                        <mat-form-field>
                            <mat-label>Unit of measure</mat-label>
                            <mat-select formControlName="measure_id">
                                <mat-option *ngFor="let measure of productMeasures" [value]="measure.id">{{ measure.unit | titlecase }} {{ measure.symbol ? "(" + measure.symbol + ")" : '' }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <mat-action-row>
                    <button mat-stroked-button type="reset" (click)="clearFilters()">Clear</button>

                    <button mat-flat-button color="primary" type="submit">
                        <mat-icon>search</mat-icon>
                        Search
                    </button>
                </mat-action-row>
            </mat-expansion-panel>
        </form>

        <div class="col-12 table-responsive">
            <table mat-table recycleRows [dataSource]="products">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let product">{{ product.name }} {{ product.display_measure ? ' - ' + product.display_measure : '' }}</td>
                </ng-container>

                <ng-container matColumnDef="status_id">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let product">{{ product.status?.title | titlecase }}</td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell *matCellDef="let product">
                        <button mat-button routerLink="products/edit/{{product.id}}" *appCan="'edit_products'">Edit</button>
                        <a class="hide-on-mobile" [swal]="{titleText: 'Are you sure you want to archive this Product?', text: 'You can\'t undo this action.', icon: 'warning'}"
                                (confirm)="onArchive(product.id)"
                                *appCan="'delete_products'"
                                mat-button>Archive</a>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" style="text-align: center;" colspan="3">No default products have been found...</td>
                </tr>
            </table>

            B r schewitz
            20-76-14
            03294617

            Charlotte Norris
            30 94 44
            45727368

            <mat-paginator
                [length]="totalRows"
                [pageIndex]="currentPage"
                [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions"
                (page)="pageChanged($event)"
                aria-label="Select page"
                showFirstLastButtons
            >
            </mat-paginator>
        </div>
    </div>
</div>
