<mat-toolbar color="primary">
    <mat-toolbar-row>
        <button mat-icon-button>
            <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
        </button>

        <img class="fire-survey-pro-logo hide-on-mobile" src="assets\images\logo\FSP_Orange_BG.png" alt="Fire Survey Pro Logo" />

        <span class="flex-spacer"></span>

        <button
                class="pill d-none d-md-inline"
                *ngIf="authService.credits < (authService.getTenantSetting('low_credit_warning')?.payload ?? 400) && authService.credits >= 0"
                mat-raised-button
                (click)="purchaseCredits()"
                style="margin-right: 10px; background-color: #ffee58 !important; color: black;"
        >
            <mat-icon style="margin-right: 5px; line-height: 23px; font-size: 20px;">warning</mat-icon>
            Low Credits
        </button>

        <button mat-raised-button (click)="purchaseCredits()" color="accent" tourAnchor="dashboard.credits" style="margin-right: 10px;">
            <mat-icon style="margin-right: 5px; line-height: 23px;">toll</mat-icon>
            {{ authService.credits | number:'' }}
        </button>

        <!-- <button *ngIf="usersService?.user?.tenants.length > 1; else singleTenant" mat-button class="float-end" [matMenuTriggerFor]="tenantMenu">
            <mat-icon>arrow_drop_down</mat-icon>
            {{ authService.tenant.name }}
        </button>

        <mat-menu #tenantMenu="matMenu" *ngIf="usersService?.user?.tenants.length > 1">
            <button *ngFor="let tenant of usersService.user.tenants" mat-menu-item (click)="switchTenant(tenant)">
                <span>{{ tenant.name }}</span>
            </button>
        </mat-menu> -->

        <ng-container *ngIf="usersService.user.id === 1">
            <button class="hide-on-mobile" mat-icon-button [matMenuTriggerFor]="developerMenu">
                <mat-icon>terminal</mat-icon>
            </button>

            <mat-menu #developerMenu="matMenu">
                <button mat-menu-item (click)="throwTestError()">
                    <mat-icon mat-list-icon>bug_report</mat-icon>
                    Test Sentry Error
                </button>
            </mat-menu>
        </ng-container>

        <button class="hide-on-mobile" mat-icon-button [matMenuTriggerFor]="helpMenu" tourAnchor="dashboard.help">
            <mat-icon>help</mat-icon>
        </button>

        <mat-menu #helpMenu="matMenu">
            <mat-action-list>
                <mat-list-item (click)="openExternalLink('help')">
                    <mat-icon mat-list-icon>import_contacts</mat-icon>
                    <div matLine>Help Guide</div>
                    <div matLine>Click here to view support documentation for the Fire Survey Pro app.</div>
                </mat-list-item>

                <mat-list-item (click)="openExternalLink('ticket')">
                    <mat-icon mat-list-icon>support</mat-icon>
                    <div matLine>Having Issues?</div>
                    <div matLine>Click here to raise a support ticket.</div>
                </mat-list-item>

                <mat-list-item (click)="openExternalLink('email')">
                    <mat-icon mat-list-icon>email</mat-icon>
                    <div matLine>Email Us</div>
                    <div matLine>support&#64;firesurveypro.co.uk</div>
                </mat-list-item>

                <mat-list-item (click)="openExternalLink('phone')">
                    <mat-icon mat-list-icon>phone</mat-icon>
                    <div matLine>Call Us</div>
                    <div matLine>01482 425516</div>
                </mat-list-item>
            </mat-action-list>
        </mat-menu>

        <button mat-button class="float-end" [matMenuTriggerFor]="userMenu" tourAnchor="dashboard.user-menu">
            <mat-icon class="me-2">account_circle</mat-icon>
            <div style="display: inline-block;">
                <span style="display: block; line-height: normal;">{{ authService.tenant.name }}</span> <span style="display: block; float: left; line-height: normal; font-weight: lighter;">{{ usersService.user.name }}</span>
            </div>
        </button>

        <mat-menu #userMenu="matMenu">
            <button mat-menu-item (click)="toggleTheme()">
                <mat-icon>{{ currentTheme === 'light-theme' ? 'dark_mode' : 'light_mode' }}</mat-icon>
                <span>{{ currentTheme === 'light-theme' ? 'Dark Mode' : 'Light Mode' }}</span>
            </button>

            <mat-divider></mat-divider>

            <button mat-menu-item (click)="openUserPreferences()">
                <mat-icon>manage_accounts</mat-icon>
                <span>User Settings</span>
            </button>

            <mat-divider></mat-divider>

            <button mat-menu-item (click)="logout()">
                <mat-icon>logout</mat-icon>
                <span>Sign Out</span>
            </button>
        </mat-menu>
    </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container>
    <mat-sidenav class="side-bar-expanded" [mode]="(themeService.handset| async) ? 'side' : 'push'" [opened]="!!(themeService.handset | async)" tourAnchor="dashboard.navigation" #sidenav>
        <div class="login-logo hide-on-desktop"></div>

        <mat-nav-list>
            <div mat-subheader>Navigation</div>
            <ng-container *ngFor="let link of links">
                <a
                        mat-list-item
                        *ngIf="link.permission ? (link.permission | hasPermission) : true"
                        routerLink="/{{ link.url }}"
                        routerLinkActive="active"
                >
                    <mat-icon mat-list-icon>{{ link.icon }}</mat-icon>
                    {{ link.name }} </a>
            </ng-container>
        </mat-nav-list>

        <mat-action-list>
            <mat-divider></mat-divider>
            <div mat-subheader>Support</div>

            <button mat-list-item (click)="purchaseCredits()">
                <mat-icon mat-list-icon>toll</mat-icon>
                <div matLine>Buy Credits</div>
                <div matLine>{{ authService.credits }}</div>
            </button>

            <button mat-list-item [matMenuTriggerFor]="helpMenu">
                <mat-icon mat-list-icon>help</mat-icon>
                <div matLine>Help Centre</div>
            </button>

            <mat-divider></mat-divider>
        </mat-action-list>

        <div class="app-store-icons mt-2" tourAnchor="dashboard.mobile">
            <a href="https://apps.apple.com/us/app/fire-survey-pro/id6479811537" target="_blank">
                <img ngSrc="../../../assets/images/apple_appstore_icon.svg" alt="Apple App Store Icon" height="40" width="120" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=surveyorpro.firesurveypro" target="_blank">
                <img ngSrc="../../../assets/images/android_appstore_icon.png" alt="Android App Store Icon" class="m-1" height="80" width="270" />
            </a>
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>

<div class="centered-tour-element" tourAnchor="dashboard.welcome"></div>

<app-admin-footer></app-admin-footer>
