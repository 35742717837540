import {HttpClient, HttpErrorResponse}                                        from '@angular/common/http';
import {Component, OnInit}                                                    from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatSnackBar}                                                          from '@angular/material/snack-bar';
import {Router}                                                               from '@angular/router';
import {AddressType}                                                          from '@src/app/admin/settings/models/addresses.model';
import {CustomSnackbarComponent}                                              from '@src/app/shared/components/custom-snackbar.component';
import {environment}                                                          from '@src/environments/environment';
import {AuthService}                                                          from '../../services/auth.service';
import {CustomValidators}                                                     from '../../validators/check-password';

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit
{
    public signupForm: UntypedFormGroup;
    public error: any;
    public submitted = false;
    public isBillingTheSameAsTrading = true;
    public countries = [];
    public tradingCountrySearch = [];
    public billingCountrySearch = [];
    public defaultCountry = {
        'flags': {
            'png': 'https://flagcdn.com/w320/gb.png',
            'svg': 'https://flagcdn.com/gb.svg',
            'alt': 'The flag of the United Kingdom — the Union Jack — has a blue field. It features the white-edged red cross of Saint George superimposed on the diagonal red cross of Saint Patrick which is superimposed on the diagonal white cross of Saint Andrew.'
        },
        'name': {
            'common': 'United Kingdom',
            'official': 'United Kingdom of Great Britain and Northern Ireland',
            'nativeName': {
                'eng': {
                    'official': 'United Kingdom of Great Britain and Northern Ireland',
                    'common': 'United Kingdom'
                }
            }
        },
        'cca2': 'GB'
    };

    constructor(
        private router: Router,
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private snackBar: MatSnackBar,
        private httpClient: HttpClient
    )
    {
        this.countries.push(this.defaultCountry);
        this.httpClient.get('https://restcountries.com/v3.1/all?fields=name,flags,cca2').subscribe(
            (res: any) => {
                this.countries = res;
                const gbCountry = this.countries.find(country => country.cca2 === 'GB');
                if (gbCountry) {
                    this.f.trading_address.get('country').setValue(gbCountry);
                    this.f.billing_address.get('country').setValue(gbCountry);
                } else {
                    this.countries.push(this.defaultCountry);
                }

                this.tradingCountrySearch = this.countries;
                this.billingCountrySearch = this.countries;
            },
            error => {
                this.countries.push(this.defaultCountry);
                this.tradingCountrySearch = this.countries;
                this.billingCountrySearch = this.countries;
            }
        );
    }

    ngOnInit()
    {
        this.signupForm = this.fb.group({
            company_name: [null, [Validators.required]],
            billing_email: [null, [Validators.required]],
            name: [null, [Validators.required]],
            email: [null, [Validators.required, Validators.email]],
            phone: [null, [Validators.required]],
            password: [null, [Validators.required]],
            password_confirmation: [null, [Validators.required]],
            frontend_url: [`${environment.localPath}/email-verified`],
            trading_address: new UntypedFormGroup({
                address_id: new UntypedFormControl(null),
                type_id: new UntypedFormControl(AddressType.TradingAddress ?? null),
                title: new UntypedFormControl('Trading Address'),
                address_line_1: new UntypedFormControl(null, Validators.required),
                address_line_2: new UntypedFormControl(null),
                address_line_3: new UntypedFormControl(null),
                locality: new UntypedFormControl(null),
                county: new UntypedFormControl(null),
                country: new UntypedFormControl(this.defaultCountry, Validators.required),
                postcode: new UntypedFormControl(null, Validators.required),
            }),
            billing_address: new UntypedFormGroup({
                address_id: new UntypedFormControl(null),
                type_id: new UntypedFormControl(AddressType.BillingAddress ?? null),
                title: new UntypedFormControl('Billing Address'),
                address_line_1: new UntypedFormControl(null, Validators.required),
                address_line_2: new UntypedFormControl(null),
                address_line_3: new UntypedFormControl(null),
                locality: new UntypedFormControl(null),
                county: new UntypedFormControl(null),
                country: new UntypedFormControl(this.defaultCountry, Validators.required),
                postcode: new UntypedFormControl(null, Validators.required),
            }),
        }, {
            Validators: CustomValidators.MatchingPasswords
        });
    }

    get f()
    {
        return this.signupForm.controls;
    }

    public onSubmit(): void
    {
        this.submitted = true;

        if (this.isBillingTheSameAsTrading) {
            this.f.trading_address.setValue(this.f.billing_address.value);
        }

        this.f.trading_address.get('type_id').setValue(AddressType.TradingAddress);
        this.f.trading_address.get('title').setValue('Trading Address');

        if (this.signupForm.invalid) {
            return;
        }

        if (this.f.password.value === this.f.password_confirmation.value) {
            this.signupForm.disable();

            this.authService.register(this.signupForm.value).subscribe({
                next: (response) => {
                    this.snackBar.open('Signed Up successfully. Please check your inbox for an email.', 'Ok');
                    this.router.navigate(['/code-verification'], { queryParams: { hash: response.data.hash, email: response.data.user.email } });
                },
                error: (response: HttpErrorResponse) => {
                    this.signupForm.enable();

                    if (response.status === 422) {
                        const validationMessages = [];

                        for (const field in response.error.errors) {
                            const propertyErrors: Array<string> = response.error.errors[field];
                            propertyErrors.forEach(error => validationMessages.push(error));
                        }

                        return this.snackBar.openFromComponent(CustomSnackbarComponent, {
                            duration: 20000,
                            data: validationMessages
                        });
                    }

                    this.snackBar.open("There has been an issue getting you signed up, please try again.", 'Ok');
                }
            });
        } else {
            this.snackBar.open('Passwords don\'t match', 'OK', {duration: 2500});
        }
    }

    ConfirmedValidator(controlName: string, matchingControlName: string)
    {
        return (formGroup: UntypedFormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];
            if (
                matchingControl.errors &&
                !matchingControl.errors.confirmedValidator
            ) {
                return;
            }
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({confirmedValidator: true});
            } else {
                matchingControl.setErrors(null);
            }
        };
    }

    public differentTradingAddress()
    {
        this.isBillingTheSameAsTrading = !this.isBillingTheSameAsTrading;
        this.f.trading_address.reset();
        const gbCountry = this.countries.find(country => country.cca2 === 'GB');
        if (gbCountry) {
            this.f.trading_address.get('country').setValue(gbCountry);
        }
    }

    public forceUppercaseConditionally(formGroup: string, formControlName: string, event: Event)
    {
        const input = event.target as HTMLInputElement;
        this.signupForm.get(`${formGroup}.${formControlName}`).setValue(input.value.toUpperCase());
    }

    public searchTradingCountries(event: Event)
    {
        const input = event.target as HTMLInputElement;
        input.value != '' ? this.tradingCountrySearch = this.countries.filter(c => c.name.common.toLowerCase().includes(input.value.toLowerCase())) : this.tradingCountrySearch = this.countries;
    }

    public searchBillingCountries(event: Event)
    {
        const input = event.target as HTMLInputElement;
        input.value != '' ? this.billingCountrySearch = this.countries.filter(c => c.name.common.toLowerCase().includes(input.value.toLowerCase())) : this.billingCountrySearch = this.countries;
    }
}
