import {Component, Inject}               from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-custom-snackbar',
    template: `
        <ul class="ms-3">
            <li *ngFor="let message of data">{{ message }}</li>
        </ul>
        <button mat-button (click)="snackBar.dismiss()">Ok.</button>`,
    styles: ['ul { margin: 0; padding: 0; list-style-type: disc; }']
})
export class CustomSnackbarComponent
{
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: string[],
        public snackBar: MatSnackBar
    )
    {
    }
}
