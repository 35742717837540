<h2 mat-dialog-title>{{modal_type}} Manufacturer Price</h2>

<form [formGroup]="pricingForm" (ngSubmit)="onSubmit()">
	<div mat-dialog-content>
		<div class="row">
			<div class="col-12 col-md-6">
				<mat-form-field>
					<mat-label>Manufacturer</mat-label>
					<mat-select formControlName="manufacturer_id" required>
						<mat-option *ngFor="let manufacturer of manufacturers" [value]="manufacturer.id">{{ manufacturer.name | titlecase }}</mat-option>
					</mat-select>
				</mat-form-field>

				<div class="text-danger" *ngIf="pricingForm.controls.manufacturer_id.errors && (pricingForm.controls.manufacturer_id.dirty || pricingForm.controls.manufacturer_id.touched) ">
					<div *ngIf="pricingForm.controls.manufacturer_id.errors.required" padding>
						*Please select a Manufacturer.
					</div>
				</div>
			</div>

			<div class="col-12 col-md-6">
				<mat-form-field>
					<mat-label>Price effective from</mat-label>
					<input matInput type="date" formControlName="start_at">
					<!-- <mat-hint>MM/DD/YYYY</mat-hint>
						<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-datepicker #picker></mat-datepicker> -->
				</mat-form-field>

				<div class="text-danger"
					*ngIf="pricingForm.controls.start_at.errors && (pricingForm.controls.start_at.dirty || pricingForm.controls.start_at.touched) ">
					<div *ngIf="pricingForm.controls.start_at.errors.required" padding>
						*Please select a Price effective from date.
					</div>
				</div>
			</div>

			<div class="col-12">
				<h3 style="display: inline-block;">Default Price</h3>
				<button style="display: inline-block;" type="button" mat-icon-button
					matTooltip="This price is used as the base price when a pricing tier is not found.">
					<mat-icon>info</mat-icon>
				</button>
			</div>

			<div class="col-12 col-md-3">
				<mat-form-field>
					<mat-label>Operative Cost (£)</mat-label>
					<input matInput type="number" formControlName="operative_cost" required />
				</mat-form-field>
				<div class="text-danger" *ngIf="pricingForm.controls.operative_cost.errors && (pricingForm.controls.operative_cost.dirty || pricingForm.controls.operative_cost.touched) ">
					<div *ngIf="pricingForm.controls.operative_cost.errors.required" padding>
						*Please enter a Operative Cost.
					</div>
				</div>
			</div>

			<div class="col-12 col-md-3">
				<mat-form-field>
					<mat-label>Sub Contractor Cost (£)</mat-label>
					<input matInput type="number" formControlName="subcontractor_cost" required />
				</mat-form-field>
				<div class="text-danger"
					*ngIf="pricingForm.controls.subcontractor_cost.errors && (pricingForm.controls.subcontractor_cost.dirty || pricingForm.controls.subcontractor_cost.touched) ">
					<div *ngIf="pricingForm.controls.subcontractor_cost.errors.required" padding>
						*Please enter a Sub Contractor Cost.
					</div>
				</div>
			</div>

			<div class="col-12 col-md-3">
				<mat-form-field>
					<mat-label>Material Cost (£)</mat-label>
					<input matInput type="number" formControlName="material_cost" required />
				</mat-form-field>
				<div class="text-danger"
					*ngIf="pricingForm.controls.material_cost.errors && (pricingForm.controls.material_cost.dirty || pricingForm.controls.material_cost.touched) ">
					<div *ngIf="pricingForm.controls.material_cost.errors.required">
						*Please enter a Material Cost.
					</div>
				</div>
			</div>

			<div class="col-12 col-md-3 mb-2">
				<mat-form-field>
					<mat-label>Sell (£)</mat-label>
					<input matInput type="number" formControlName="sell_price" required />
				</mat-form-field>
				<div class="text-danger"
					*ngIf="pricingForm.controls.sell_price.errors && (pricingForm.controls.sell_price.dirty || pricingForm.controls.sell_price.touched) ">
					<div *ngIf="pricingForm.controls.sell_price.errors.required">
						*Please enter a Sell Price.
					</div>
				</div>
			</div>

			<hr />
		</div>

		<div class="row" *ngIf="modal_type == 'Edit'">
			<div class="col-12">
				<h3 style="display: inline-block;">Pricing Tiers</h3>
				<button style="display: inline-block;" type="button" mat-icon-button
					matTooltip="Pricing tiers are used when you want to change your pricing based on sizing, using this for Ablative Batt is an example of where this can be useful.">
					<mat-icon>info</mat-icon>
				</button>

				<table mat-table [dataSource]="dataSource" formArrayName="pricing_tiers">
					<!-- <div class="row"
						*ngFor="let product_measurement of pricingForm.get('pricing_tiers')['controls']; let i = index;"
						[formGroupName]="i"> -->
                        <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; let i = index; columns: displayColumns;"></tr>
                        <tr mat-footer-row *matFooterRowDef="['tier']; sticky: true"></tr>

                        <ng-container matColumnDef="tier">
                            <th mat-header-cell *matHeaderCellDef> Tier </th>
                            <td mat-cell *matCellDef="let row; let index = index"  [formGroupName]="index">
                                <mat-form-field class="mx-2">
                                    <input matInput type="text" formControlName="tier" (keyup)="pricingTierValueChange(index)" required />
                                </mat-form-field>
                                <div class="text-danger"
                                    *ngIf="pricingForm.controls.tier.errors && (pricingForm.controls.tier.dirty || pricingForm.controls.tier.touched) ">
                                    <div *ngIf="pricingForm.controls.tier.errors.required">
                                        *Please enter a Tier.
                                    </div>
                                </div>
                            </td>
                            <td mat-footer-cell *matFooterCellDef [colSpan]="6">
                                <mat-paginator
                                    [length]="totalRows"
                                    [pageIndex]="currentPage"
                                    [pageSize]="pageSize"
                                    [pageSizeOptions]="pageSizeOptions"
                                    (page)="pageChanged($event)"
                                    showFirstLastButtons
                                >
                                </mat-paginator>
                            </td>
                        </ng-container>

						<ng-container matColumnDef="operative_cost">
                            <th mat-header-cell *matHeaderCellDef> Operative Cost (£) </th>
                            <td mat-cell *matCellDef="let row; let index = index"  [formGroupName]="index">
                                <mat-form-field class="mx-2">
                                    <input matInput type="number" formControlName="operative_cost" (keyup)="pricingTierValueChange(index)" required />
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="subcontractor_cost">
                            <th mat-header-cell *matHeaderCellDef> Sub Contractor Cost (£) </th>
                            <td mat-cell *matCellDef="let row; let index = index"  [formGroupName]="index">
                                <mat-form-field class="mx-2">
                                    <input matInput type="number" formControlName="subcontractor_cost" (keyup)="pricingTierValueChange(index)" required />
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="material_cost">
                            <th mat-header-cell *matHeaderCellDef> Material Cost (£) </th>
                            <td mat-cell *matCellDef="let row; let index = index"  [formGroupName]="index">
                                <mat-form-field class="mx-2">
                                    <input matInput type="number" formControlName="material_cost" (keyup)="pricingTierValueChange(index)" required />
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="sell_price">
                            <th mat-header-cell *matHeaderCellDef> Sell (£) </th>
                            <td mat-cell *matCellDef="let row; let index = index"  [formGroupName]="index">
                                <mat-form-field class="mx-2">
                                    <input matInput type="number" formControlName="sell_price" (keyup)="pricingTierValueChange(index)" required />
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> Actions </th>
                            <td mat-cell *matCellDef="let row; let index = index"  [formGroupName]="index">
                                <button mat-stroked-button color="error" *ngIf="pricingForm.get('pricing_tiers').value.length > 1" [swal]="{titleText: 'Are you sure you want to archive this price?', text: 'You can\'t undo this action. This will affect pricing for any sites this product is used on.', icon: 'warning'}" (confirm)="deleteProductPrice(index)">
                                    Delete
                                </button>
                            </td>
                        </ng-container>

						<!-- <div class="col-12">
							<div class="text-danger"
								*ngIf="pricingForm.get('pricing_tiers')['controls'][i].controls.tier.errors && (pricingForm.get('pricing_tiers')['controls'][i].controls.tier.dirty || pricingForm.get('pricing_tiers')['controls'][i].controls.tier.touched) ">
								<div *ngIf="pricingForm.get('pricing_tiers')['controls'][i].controls.tier.errors.required" padding>
									*Please enter a Tier.
								</div>

								<div *ngIf="pricingForm.get('pricing_tiers')['controls'][i].controls.tier.errors.min" padding>
									*Please enter a Tier value above 0.01.
								</div>
							</div>

							<div class="text-danger"
								*ngIf="pricingForm.get('pricing_tiers')['controls'][i].controls.operative_cost.errors && (pricingForm.get('pricing_tiers')['controls'][i].controls.operative_cost.dirty || pricingForm.get('pricing_tiers')['controls'][i].controls.operative_cost.touched) ">
								<div *ngIf="pricingForm.get('pricing_tiers')['controls'][i].controls.operative_cost.errors.required" padding>
									*Please enter a Operative Cost.
								</div>
							</div>

							<div class="text-danger"
								*ngIf="pricingForm.get('pricing_tiers')['controls'][i].controls.subcontractor_cost.errors && (pricingForm.get('pricing_tiers')['controls'][i].controls.subcontractor_cost.dirty || pricingForm.get('pricing_tiers')['controls'][i].controls.subcontractor_cost.touched) ">
								<div *ngIf="pricingForm.get('pricing_tiers')['controls'][i].controls.subcontractor_cost.errors.required" padding>
									*Please enter a Sub Contractor Cost.
								</div>
							</div>

							<div class="text-danger"
								*ngIf="pricingForm.get('pricing_tiers')['controls'][i].controls.material_cost.errors && (pricingForm.get('pricing_tiers')['controls'][i].controls.material_cost.dirty || pricingForm.get('pricing_tiers')['controls'][i].controls.material_cost.touched) ">
								<div *ngIf="pricingForm.get('pricing_tiers')['controls'][i].controls.material_cost.errors.required" padding>
									*Please enter a Material Cost.
								</div>
							</div>

							<div class="text-danger"
								*ngIf="pricingForm.get('pricing_tiers')['controls'][i].controls.sell_price.errors && (pricingForm.get('pricing_tiers')['controls'][i].controls.sell_price.dirty || pricingForm.get('pricing_tiers')['controls'][i].controls.sell_price.touched) ">
								<div *ngIf="pricingForm.get('pricing_tiers')['controls'][i].controls.sell_price.errors.required" padding>
									*Please enter a Sell Price.
								</div>
							</div>
						</div> -->
					<!-- </div> -->
				</table>
			</div>
		</div>
	</div>

	<div mat-dialog-actions align="end">
		<button mat-stroked-button mat-dialog-close>Cancel</button>
		<button class="float-end" [class.spinner]="loading" [disabled]="loading" color="primary" mat-raised-button
			color="primary" cdkFocusInitial>Save</button>
	</div>

</form>
