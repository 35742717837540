<div class="p-4 pt-0 align-self-center min-login-size" style="position: absolute;">
    <div class="login-logo" routerLink="/"></div>
    <mat-card>
        <mat-card-header>
            <mat-card-title class="mt-2">Verification Code</mat-card-title>
            <mat-card-subtitle>Please enter the code you have been sent in your email.</mat-card-subtitle>


        </mat-card-header>
        <mat-card-content class="mt-2">
            <ng-otp-input #otpInput (onInputChange)="onOtpChange($event)" [config]="otpConfig"></ng-otp-input>

            <div class="col-12 mt-3">
                <span *ngIf="(timeRemaining$ | async) > 0; else requestEmail" class="text-muted a-link-margin">
                    Didn't receive your email? Request another in <br />
                    <span class="text-primary a-link-margin">{{ timeRemaining$ | async | date:'ss' }} seconds</span>
                </span>
                <ng-template #requestEmail>
                    <a class="text-primary a-link-margin" (click)="onResendOtp()">Didn't receive the code?</a>
                </ng-template>
            </div>
        </mat-card-content>
    </mat-card>
</div>
