import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    public loginForm: UntypedFormGroup;
    public error: any;
    public submitted = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private snackBar: MatSnackBar,
    ) {
        if (this.authService.authenticated) {
            this.router.navigate(['/app']).then(r => r);
        }
    }

    get f() {
        return this.loginForm.controls;
    }

    public ngOnInit(): void {
        this.loginForm = this.fb.group({
            email: [null, [Validators.required, Validators.email]],
            password: [null, Validators.required]
        });
    }

    public onSubmit(): void {
        this.submitted = true;

        if (this.loginForm.invalid) {
            return;
        }

        this.loginForm.disable();

        this.authService.login(this.loginForm.value).subscribe(
            () => {
                const redirectUrl = this.activatedRoute.snapshot.queryParamMap.get('redirectUrl') || '/signed-in-redirect';

                this.snackBar.open('Signed in successfully.', 'Ok', {duration: 2000});
                this.router.navigateByUrl(redirectUrl).then(r => r);
            },
            (response: any) => {
                this.loginForm.enable();
                this.error = response;

                if(response.status == 403){
                    this.snackBar.open('You have been sent a verification code, Please check your email.', 'Ok');
                    this.router.navigate(['/code-verification'], { queryParams: { hash: response.error.hash, email: this.loginForm.get('email').value } });
                } else {
                    this.snackBar.open(response?.error?.message, 'Ok');
                }
            }
        );
    }
}
