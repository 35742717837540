import { Component, ViewChild } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-otp-verification',
	templateUrl: './otp-verification.component.html',
    styleUrls: ['./otp-verification.component.scss']
})
export class OtpVerificationComponent {
    @ViewChild('otpInput') otpInput:any;

    public email: string;
    public hash: string;
    public timeRemaining$: Observable<number>;
    public otpConfig = {
        length: 6,
        allowNumbersOnly: true,
        placeholder: '-',
    };

	constructor(
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private snackBar: MatSnackBar,
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            this.email = params.email;
            this.hash = params.hash;
        });
	}

	public onOtpChange(event){
        if(event.length === this.otpConfig.length){
            this.otpInput.otpForm.disable();
            this.authService.verifyEmail(event, this.hash).subscribe({
                next: () => {
                    this.router.navigate(['/login']);
                    this.snackBar.open('Email verified successfully, you can now login.', 'Ok', {duration: 2000});
                },
                error: response => {
                    this.snackBar.open(`Invalid Code, ${response.error.message}`, 'Ok', {politeness: 'assertive'});
                    this.otpInput.otpForm.reset();
                    this.otpInput.otpForm.enable();
            }});
        }
    }

    public onResendOtp(){
        this.authService.resendVerificationEmail(this.email).subscribe({
            next: (response: any) => {
                this.hash = response.hash;
                this.snackBar.open('You have been sent a new verification code in an email.', 'Ok', {duration: 2000});
            },
            error: () => this.snackBar.open('There has been a problem resending you an email, please try again', 'Ok', {duration: 3000})
        });
        this.otpInput.otpForm.reset();
        this.otpInput.otpForm.enable();

        this.timeRemaining$ = timer(0, 1000).pipe(
            map(n => (30 - n) * 1000),
            takeWhile(n => n >= 0),
        );
    }
}
