import {Injectable}               from '@angular/core';
import {AuthService}              from '@src/app/core/services/auth.service';
import {Walkthrough}              from '@src/app/shared/models/walkthrough.model';
import {ToastrService}            from 'ngx-toastr';
import {IStepOption, TourService} from 'ngx-ui-tour-md-menu';
import {ApiService}               from '../api.service';

@Injectable({
    providedIn: 'root'
})
export class WalkthroughService
{
    public walkthrough: Walkthrough = null;

    constructor(
        public tourService: TourService,
        private authService: AuthService,
        private apiService: ApiService,
        private toastService: ToastrService
    )
    {
        this.tourService.events$.subscribe(async x => {
            // if (x.name === 'stepShow') {
            //     await this.completeStep(x.value);
            // }

            // if (x.name === 'end' && !this.walkthrough?.is_completed) {
            //     await this.userDroppedOut(this.walkthrough.id);
            // }
        });
    }

    public async start(feature: string, restartTour: boolean): Promise<void>
    {
        this.walkthrough = this.getWalkthrough(feature);

        if (this.walkthrough) {
            this.setTourSteps(feature, restartTour).then((startWalkthrough: boolean) => {
                if (startWalkthrough) {
                    this.tourService.start();
                }
            }).catch(() => {
                this.errorMessage();
            });
        }
    }

    private async setTourSteps(feature: string, restartTour: boolean): Promise<boolean>
    {
        return new Promise(async (resolve, reject) => {
            if (this.walkthrough.has_dropped_out || this.walkthrough.is_completed && !restartTour) {
                resolve(false);
            }

            if (restartTour) {
                await this.restartTour(feature).then(() => {
                    this.walkthrough = this.getWalkthrough(feature);
                });
            }

            if (this.walkthrough && this.walkthrough.items.length > 0) {
                this.tourService.initialize(this.walkthrough.items, {
                    enableBackdrop: false,
                    closeOnOutsideClick: false,
                });

                resolve(true);
            }
        });
    }

    private completeStep(step: IStepOption): Promise<void>
    {
        return new Promise((resolve, reject) => {
            this.apiService.get(`walkthrough-items/${step.stepId}/complete`).subscribe({
                next: (response) => {
                    this.authService.walkthroughs = response.data;
                    this.walkthrough = this.getWalkthrough(this.walkthrough.feature);
                    resolve();
                },
                error: (error) => reject(error)
            });
        });
    }

    private async userDroppedOut(walkthroughId: string): Promise<void>
    {
        return new Promise((resolve, reject) => {
            debugger;
            this.apiService.get(`walkthroughs/${walkthroughId}/drop-out`).subscribe({
                next: (response) => {
                    this.authService.walkthroughs = response.data;
                    resolve();
                },
                error: (error) => {
                    this.errorMessage();
                    reject(error);
                }
            });
        });
    }

    private restartTour(feature: string): Promise<void>
    {
        return new Promise((resolve, reject) => {
            this.apiService.get(`walkthroughs/${feature}/reset`).subscribe({
                next: (response) => {
                    this.authService.walkthroughs = response.data;
                    resolve();
                },
                error: (error) => {
                    this.errorMessage();
                    reject(error);
                }
            });
        });
    }

    private getWalkthrough(feature: string): Walkthrough | null
    {
        let walkthrough: Walkthrough = this.authService.walkthroughs.find(walkthrough => walkthrough.feature === feature);
        return walkthrough ?? null;
    }

    private errorMessage(): void
    {
        this.toastService.error('An error occurred while starting the walkthrough.');
    }
}
