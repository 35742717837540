<app-page-header title="Products" [breadcrumbs]="breadcrumbs" [buttons]="buttons"></app-page-header>

<div class="container-fluid">
    <mat-tab-group>
        <mat-tab label="Default Products">
            <app-default-products></app-default-products>
        </mat-tab>

        <mat-tab label="Custom Products">
            <app-custom-products></app-custom-products>
        </mat-tab>
    </mat-tab-group>
</div>
