import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {PermissionsService}                              from '@src/app/core/services/permissions.service';

@Directive({
    selector: '[appCan]'
})
export class CanDirective {
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private permissionsService: PermissionsService,
    ) {
    }

    @Input()
    set appCan(permission: string) {
        //Removed the below comment for testing
        // this.permissionsService.hasRole('super-admin') ||
        if (this.permissionsService.can(permission)) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainerRef.clear();
        }
    }
}
