<div class="page-header">
    <div class="row">
        <ol class="breadcrumb hide-on-mobile">
            <li class="breadcrumb-item" [ngClass]="breadcrumb.active ? 'active' : null" *ngFor="let breadcrumb of breadcrumbs">
                <a [attr.disabled]="breadcrumb.active" [routerLink]="breadcrumb?.url" [queryParams]="breadcrumb?.queryParams">
                    {{ breadcrumb.title }}
                </a>
            </li>
        </ol>
    </div>

    <div class="row">
        <div class="col-12 col-md-3">
            <h2>{{ title }}</h2>
        </div>

        <div class="col-12 col-md-9 mobile-actions">
            <ng-container *ngFor="let button of buttons">
                <ng-container [ngSwitch]="button.type" *appCan="button.permission">
                    <ng-container *ngSwitchCase="'link'" [ngSwitch]="button.material">
                        <a *ngSwitchDefault mat-button class="mobile-spacing float-end mx-1" [tourAnchor]="button.walkthroughAnchor" [color]="button.color" [routerLink]="button.url">{{ button.title }}</a>
                        <a *ngSwitchCase="'raised'" mat-raised-button class="mobile-spacing float-end mx-1" [tourAnchor]="button.walkthroughAnchor" [color]="button.color" [routerLink]="button.url">{{ button.title }}</a>
                        <a *ngSwitchCase="'stroked'" mat-stroked-button class="mobile-spacing float-end mx-1" [tourAnchor]="button.walkthroughAnchor" [color]="button.color" [routerLink]="button.url">{{ button.title }}</a>
                        <a *ngSwitchCase="'flat'" mat-flat-button class="mobile-spacing float-end mx-1" [tourAnchor]="button.walkthroughAnchor" [color]="button.color" [routerLink]="button.url">{{ button.title }}</a>
                        <a *ngSwitchCase="'icon'" mat-icon-button class="mobile-spacing float-end mx-1" [tourAnchor]="button.walkthroughAnchor" [color]="button.color" [routerLink]="button.url">{{ button.title }}</a>
                        <a *ngSwitchCase="'fab'" mat-fab class="mobile-spacing float-end mx-1" [tourAnchor]="button.walkthroughAnchor" [color]="button.color" [routerLink]="button.url">{{ button.title }}</a>
                        <a *ngSwitchCase="'mini-fab'" mat-mini-fab class="mobile-spacing float-end mx-1" [tourAnchor]="button.walkthroughAnchor" [color]="button.color" [routerLink]="button.url">{{ button.title }}</a>
                    </ng-container>

                    <ng-container *ngSwitchCase="'button'" [ngSwitch]="button.material">
                        <button *ngSwitchDefault mat-button class="mobile-spacing float-end mx-1" [tourAnchor]="button.walkthroughAnchor" [color]="button.color" (click)="callback(button)">{{ button.title }}</button>
                        <button *ngSwitchCase="'raised'" mat-raised-button class="mobile-spacing float-end mx-1" [tourAnchor]="button.walkthroughAnchor" [color]="button.color" (click)="callback(button)">{{ button.title }}</button>
                        <button *ngSwitchCase="'stroked'" mat-stroked-button class="mobile-spacing float-end mx-1" [tourAnchor]="button.walkthroughAnchor" [color]="button.color" (click)="callback(button)">{{ button.title }}</button>
                        <button *ngSwitchCase="'flat'" mat-flat-button class="mobile-spacing float-end mx-1" [tourAnchor]="button.walkthroughAnchor" [color]="button.color" (click)="callback(button)">{{ button.title }}</button>
                        <button *ngSwitchCase="'icon'" mat-icon-button class="mobile-spacing float-end mx-1" [tourAnchor]="button.walkthroughAnchor" [color]="button.color" (click)="callback(button)">{{ button.title }}</button>
                        <button *ngSwitchCase="'fab'" mat-fab class="mobile-spacing float-end mx-1" [tourAnchor]="button.walkthroughAnchor" [color]="button.color" (click)="callback(button)">{{ button.title }}</button>
                        <button *ngSwitchCase="'mini-fab'" mat-mini-fab class="mobile-spacing float-end mx-1" [tourAnchor]="button.walkthroughAnchor" [color]="button.color" (click)="callback(button)">{{ button.title }}</button>

                        <div *ngSwitchCase="'siteStatus'" class="hide-on-mobile">
                            <div *ngIf="siteStatuses">
                                <button mat-raised-button [matMenuTriggerFor]="siteStatusMenu" class="float-end mx-1 site-btn-right" [tourAnchor]="button.walkthroughAnchor" [color]="button.color">
                                    <mat-icon>expand_more</mat-icon>
                                </button>

                                <mat-menu #siteStatusMenu="matMenu">
                                    <button mat-menu-item *ngFor="let status of getSiteStatusByPhase(1)" [disabled]="checkIfDisabled(status.id)" (click)="callback(button, status)" matTooltipPosition="left" matTooltip="{{status.data?.isSyncable ? 'This status will be available to sync onto devices' : 'This status will be unable to sync onto devices' }}">
                                        <span><mat-icon>{{ status.data?.isSyncable ? 'sync' : '' }}</mat-icon>{{status.title | titlecase}}</span>
                                    </button>
                                    <hr />

                                    <button mat-menu-item *ngFor="let status of getSiteStatusByPhase(2)" [disabled]="checkIfDisabled(status.id)" (click)="callback(button, status)" matTooltipPosition="left" matTooltip="{{status.data?.isSyncable ? 'This status will be available to sync onto devices' : 'This status will be unable to sync onto devices' }}">
                                        <span><mat-icon>{{ status.data?.isSyncable ? 'sync' : '' }}</mat-icon>{{status.title | titlecase}}</span>
                                    </button>

                                    <hr />

                                    <button mat-menu-item *ngFor="let status of getSiteStatusByPhase()" [disabled]="this.globalService?.site?.status?.id == status?.id" matTooltipPosition="left" matTooltip="{{status.data?.isSyncable ? 'This status will be available to sync onto devices' : 'This status will be unable to sync onto devices' }}">
                                        <span><mat-icon>{{ status.data?.isSyncable ? 'sync' : '' }}</mat-icon>{{status.title | titlecase}}</span>
                                    </button>
                                </mat-menu>
                            </div>

                            <button mat-raised-button class="float-end site-btn-left" [tourAnchor]="button.walkthroughAnchor" [color]="button.color">{{ button.title | titlecase }}</button>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'info'" [ngSwitch]="button.material">
                        <div *ngSwitchCase="'displayDate'">
                            <p *ngIf="button?.data?.survey_completed_at" class="float-end m-2"><strong>{{ button.title | titlecase }}:</strong> {{button?.data?.survey_completed_at | date}}</p>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>

<div class="row" *ngIf="authService.credits < 0">
    <div class="col-12">
        <mat-card class="mx-2 mb-2" style="background-color: #f44336;">
            <mat-card-content>
                <p style="margin: 0;"><mat-icon style="font-size: 20px; vertical-align: top;">warning</mat-icon> You have now entered a negative credit balance, please purchase credits to clear your negative balance.</p>
                <button mat-raised-button class="float-end" style="margin-top: -30px;" (click)="purchaseCredits()">Buy Credits</button>
            </mat-card-content>
        </mat-card>
    </div>
</div>

