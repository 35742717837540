<div class="p-4 pt-0 align-self-center" style="position: absolute;" [ngStyle]="{'min-width': isBillingTheSameAsTrading ? '50%' : '65%'}">
	<div class="login-logo"></div>
	<mat-card>
		<mat-card-header>
			<mat-card-title class="mt-4">Sign-up</mat-card-title>
			<mat-card-subtitle>Please enter your details to complete signing up.</mat-card-subtitle>
		</mat-card-header>

		<form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
			<mat-card-content>
				<div class="row">
					<div [ngClass]="isBillingTheSameAsTrading ? 'col-md-6' : 'col-md-4'">
						<div class="row">
							<h3 class="col-12">Company Details</h3>
						</div>

						<mat-form-field>
							<input type="text" [ngClass]="{ 'is-invalid': submitted && f.company_name.errors }" matInput
								placeholder="Company Name" formControlName="company_name" autocomplete="organization">
							<div *ngIf="submitted && f.company_name.errors" class="invalid-feedback">
								<div *ngIf="f.company_name.errors.required">
									Company Name is required.
								</div>
							</div>
						</mat-form-field>

						<mat-form-field>
							<input type="text" [ngClass]="{ 'is-invalid': submitted && f.billing_email.errors }" matInput
								placeholder="Billing Email" formControlName="billing_email" autocomplete="organization">
							<div *ngIf="submitted && f.billing_email.errors" class="invalid-feedback">
								<div *ngIf="f.billing_email.errors.required">
									Billing email is required.
								</div>
							</div>
						</mat-form-field>

						<div class="row">
							<h3 class="col-12">User Details</h3>
						</div>

						<mat-form-field>
							<input type="text" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" matInput
								placeholder="Full Name" formControlName="name" autocomplete="name">
							<div *ngIf="submitted && f.name.errors" class="invalid-feedback">
								<div *ngIf="f.name.errors.required">
									Full Name is required.
								</div>
							</div>
						</mat-form-field>

						<mat-form-field>
							<input type="text" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" matInput
								placeholder="Email" formControlName="email" autocomplete="email">
							<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
								<div *ngIf="f.email.errors.required">
									Email is required.
								</div>

								<div *ngIf="f.email.errors.email">
									Please enter a valid email address.
								</div>
							</div>
						</mat-form-field>

                        <mat-form-field>
							<input type="text" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" matInput
								placeholder="Phone Number" formControlName="phone" autocomplete="phone">
							<div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
								<div *ngIf="f.phone.errors.required">
									Phone Number is required.
								</div>
							</div>
						</mat-form-field>

						<mat-form-field>
							<input type="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" matInput
								placeholder="Password" formControlName="password" autocomplete="current-password">
							<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
								<div *ngIf="f.password.errors.required">
									Password is required.
								</div>
							</div>
						</mat-form-field>

						<mat-form-field>
							<input type="password"
								[ngClass]="{ 'is-invalid': submitted && f.password_confirmation.errors }" matInput
								placeholder="Confirm Password" formControlName="password_confirmation"
								autocomplete="current-password">
							<div *ngIf="submitted && f.password_confirmation.errors" class="invalid-feedback">
								<div *ngIf="f.password_confirmation.errors.required">
									Confirm Password is required.
								</div>
							</div>
						</mat-form-field>
					</div>

					<div [ngClass]="isBillingTheSameAsTrading ? 'col-md-6' : 'col-md-4'">
						<form formGroupName="billing_address">
							<div class="row">
								<h3 class="col-12 col-md-5">Billing Address</h3>
								<mat-checkbox class="col-12 col-md-7 text-right" color="primary"
									(change)="differentTradingAddress()">Different Trading Address?</mat-checkbox>
							</div>

							<mat-form-field>
								<mat-label>Address 1</mat-label>
								<input matInput type="text" formControlName="address_line_1" required>
							</mat-form-field>
							<div class="text-danger"
								*ngIf="signupForm.get('billing_address.address_line_1').errors && (signupForm.get('billing_address.address_line_1').dirty || signupForm.get('billing_address.address_line_1').touched) ">
								<div *ngIf="signupForm.get('billing_address.address_line_1').errors.required" padding>
									*Please enter Address 1.
								</div>
							</div>

							<mat-form-field>
								<mat-label>Address 2</mat-label>
								<input matInput type="text" formControlName="address_line_2">
							</mat-form-field>
							<!-- <div class="text-danger"
								*ngIf="signupForm.get('billing_address.address_line_2').errors && (signupForm.get('billing_address.address_line_2').dirty || signupForm.get('billing_address.address_line_2').touched) ">
								<div *ngIf="signupForm.get('billing_address.address_line_2').errors.required" padding>
									*Please enter Address 2.
								</div>
							</div> -->

							<mat-form-field>
								<mat-label>Address 3</mat-label>
								<input matInput type="text" formControlName="address_line_3">
							</mat-form-field>
							<!-- <div class="text-danger"
								*ngIf="signupForm.get('billing_address.address_line_3').errors && (signupForm.get('billing_address.address_line_3').dirty || signupForm.get('billing_address.address_line_3').touched) ">
								<div *ngIf="signupForm.get('billing_address.address_line_3').errors.required" padding>
									*Please enter Address 3.
								</div>
							</div> -->

							<mat-form-field>
								<mat-label>Postcode</mat-label>
								<input matInput type="text" formControlName="postcode"
									(input)="forceUppercaseConditionally('billing_address', 'postcode', $event);"
									required />
							</mat-form-field>
							<div class="text-danger"
								*ngIf="signupForm.get('billing_address.postcode').errors && (signupForm.get('billing_address.postcode').dirty || signupForm.get('billing_address.postcode').touched) ">
								<div *ngIf="signupForm.get('billing_address.postcode').errors.required" padding>
									*Please enter postcode.
								</div>
							</div>

							<mat-form-field>
								<mat-label>Locality / City</mat-label>
								<input matInput type="text" formControlName="locality">
							</mat-form-field>
							<!-- <div class="text-danger"
								*ngIf="signupForm.get('billing_address.locality').errors && (signupForm.get('billing_address.locality').dirty || signupForm.get('billing_address.locality').touched) ">
								<div *ngIf="signupForm.get('billing_address.locality').errors.required" padding>
									*Please enter locality / city.
								</div>
							</div> -->

							<mat-form-field>
								<mat-label>County</mat-label>
								<input matInput type="text" formControlName="county">
							</mat-form-field>
							<!-- <div class="text-danger"
								*ngIf="signupForm.get('billing_address.county').errors && (signupForm.get('billing_address.county').dirty || signupForm.get('billing_address.county').touched) ">
								<div *ngIf="signupForm.get('billing_address.county').errors.required" padding>
									*Please enter County.
								</div>
							</div> -->

							<mat-form-field>
								<mat-label>Country</mat-label>
								<mat-select formControlName="country" required>
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel="Search for Countries" noEntriesFoundLabel="No matching country found" (keyup)="searchBillingCountries($event)"></ngx-mat-select-search>
                                    </mat-option>
									<mat-option *ngFor="let country of billingCountrySearch" [value]="country"><img style="max-width: 20px; margin-right: 5px;" [src]="country.flags.png"> {{country.name.common | titlecase}}</mat-option>
								</mat-select>
							</mat-form-field>
							<!-- <div class="text-danger"
								*ngIf="signupForm.get('billing_address.country').errors && (signupForm.get('billing_address.country').dirty || signupForm.get('billing_address.country').touched) ">
								<div *ngIf="signupForm.get('billing_address.country').errors.required" padding>
									*Please enter Country.
								</div>
							</div> -->
						</form>
					</div>

					<div [ngClass]="isBillingTheSameAsTrading ? 'col-md-6' : 'col-md-4'">
						<form formGroupName="trading_address" *ngIf="!isBillingTheSameAsTrading">
							<div class="row">
								<h3 class="col-12">Trading Address</h3>
							</div>

							<mat-form-field>
								<mat-label>Address 1</mat-label>
								<input matInput type="text" formControlName="address_line_1" required>
							</mat-form-field>
							<div class="text-danger"
								*ngIf="signupForm.get('trading_address.address_line_1').errors && (signupForm.get('trading_address.address_line_1').dirty || signupForm.get('trading_address.address_line_1').touched) ">
								<div *ngIf="signupForm.get('trading_address.address_line_1').errors.required" padding>
									*Please enter Address 1.
								</div>
							</div>

							<mat-form-field>
								<mat-label>Address 2</mat-label>
								<input matInput type="text" formControlName="address_line_2">
							</mat-form-field>
							<!-- <div class="text-danger"
								*ngIf="signupForm.get('trading_address.address_line_2').errors && (signupForm.get('trading_address.address_line_2').dirty || signupForm.get('trading_address.address_line_2').touched) ">
								<div *ngIf="signupForm.get('trading_address.address_line_2').errors.required" padding>
									*Please enter Address 2.
								</div>
							</div> -->

							<mat-form-field>
								<mat-label>Address 3</mat-label>
								<input matInput type="text" formControlName="address_line_3">
							</mat-form-field>
							<!-- <div class="text-danger"
								*ngIf="signupForm.get('trading_address.address_line_3').errors && (signupForm.get('trading_address.address_line_3').dirty || signupForm.get('trading_address.address_line_3').touched) ">
								<div *ngIf="signupForm.get('trading_address.address_line_3').errors.required" padding>
									*Please enter Address 3.
								</div>
							</div> -->

							<mat-form-field>
								<mat-label>Postcode</mat-label>
								<input matInput type="text" formControlName="postcode"
									(input)="forceUppercaseConditionally('trading_address', 'postcode', $event);"
									required />
							</mat-form-field>
							<div class="text-danger"
								*ngIf="signupForm.get('trading_address.postcode').errors && (signupForm.get('trading_address.postcode').dirty || signupForm.get('trading_address.postcode').touched) ">
								<div *ngIf="signupForm.get('trading_address.postcode').errors.required" padding>
									*Please enter postcode.
								</div>
							</div>

							<mat-form-field>
								<mat-label>Locality / City</mat-label>
								<input matInput type="text" formControlName="locality">
							</mat-form-field>
							<!-- <div class="text-danger"
								*ngIf="signupForm.get('trading_address.locality').errors && (signupForm.get('trading_address.locality').dirty || signupForm.get('trading_address.locality').touched) ">
								<div *ngIf="signupForm.get('trading_address.locality').errors.required" padding>
									*Please enter locality / city.
								</div>
							</div> -->

							<mat-form-field>
								<mat-label>County</mat-label>
								<input matInput type="text" formControlName="county">
							</mat-form-field>
							<!-- <div class="text-danger"
								*ngIf="signupForm.get('trading_address.county').errors && (signupForm.get('trading_address.county').dirty || signupForm.get('trading_address.county').touched) ">
								<div *ngIf="signupForm.get('trading_address.county').errors.required" padding>
									*Please enter County.
								</div>
							</div> -->

							<mat-form-field>
								<mat-label>Country</mat-label>
								<mat-select formControlName="country" required>
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel="Search for Countries" noEntriesFoundLabel="No matching country found" (keyup)="searchTradingCountries($event)"></ngx-mat-select-search>
                                    </mat-option>
									<mat-option *ngFor="let country of tradingCountrySearch" [value]="country"><img style="max-width: 20px; margin-right: 5px;" [src]="country.flags.png"> {{country.name.common | titlecase}}</mat-option>
								</mat-select>
							</mat-form-field>
							<!-- <div class="text-danger"
								*ngIf="signupForm.get('trading_address.country').errors && (signupForm.get('trading_address.country').dirty || signupForm.get('trading_address.country').touched) ">
								<div *ngIf="signupForm.get('trading_address.country').errors.required" padding>
									*Please enter Country.
								</div>
							</div> -->
						</form>
					</div>
				</div>

				<div class="col-12 mt-3">
					<div *ngIf="error" class="alert alert-danger mt-1">{{error}}</div>
				</div>
			</mat-card-content>

			<mat-card-actions class="m-0">
				<button type="submit" mat-flat-button color="primary">Submit</button>
				<a mat-button color="primary" routerLink="/login">Return to login</a>
			</mat-card-actions>
		</form>
	</mat-card>
</div>
