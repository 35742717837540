<div class="container-fluid">
    <div class="row">
        <mat-card-title>
            <h2 class="mt-3 mb-0" tourAnchor="dashboard.list">Sites by Status</h2>
        </mat-card-title>

        <mat-card-subtitle class="mb-0">Showing all sites grouped by their status for the current year to date.</mat-card-subtitle>

        <ng-container *ngIf="siteStatuses.length; else loading">
            <div *ngFor="let status of siteStatuses" class="col-md-3 col-sm-6 col-xs-12">
                <mat-card class="mt-3" [style.border-bottom]="'7px solid ' + status.data.background">
                    <mat-card-content>
                        <h2 style="display: inline-block; margin: 0;">{{ status.name | titlecase }}</h2>
                        <h1 style="float: right; margin: 0;">{{ status.total }}</h1>
                    </mat-card-content>
                </mat-card>
            </div>
        </ng-container>

        <ng-template #loading>
            <div class="col-md-3 col-sm-6 col-xs-12" *ngFor="let _ of [].constructor(8); let i = index">
                <ngx-skeleton-loader [theme]="{width: '100%', height: '66px', 'margin-top': '16px', 'margin-bottom': '0', 'background-color': skeletonBackground}" count="1" [animation]="skeletonTheme"></ngx-skeleton-loader>
            </div>
        </ng-template>
    </div>

    <div class="row">
        <div class="col-12 col-md-8">
            <mat-card class="mt-3" tourAnchor="dashboard.latest-pin-activity">
                <mat-card-title>
                    <h2>Latest Pin Activity</h2>
                </mat-card-title>

                <mat-card-subtitle>Showing the latest pin activity across all sites for the last 7 days.</mat-card-subtitle>

                <mat-card-content>
                    <ng-container>
                        <ng-container *ngIf="('view_pins' | hasPermission); else noPermission">
                            <table mat-table [dataSource]="latestPinActivity" class="w-100">
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef>Name</th>
                                    <td mat-cell *matCellDef="let pin">{{pin.identifier}}</td>
                                </ng-container>

                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef>Status</th>
                                    <td mat-cell *matCellDef="let pin">
                                        <span class="badge" *ngIf="pin.status"
                                            [style.background]="pin.status?.background"
                                            [style.color]="pin.status?.colour">
                                            {{pin.status?.title | titlecase}}
                                        </span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="site">
                                    <th mat-header-cell *matHeaderCellDef>Site</th>
                                    <td mat-cell *matCellDef="let pin">{{pin.site}}</td>
                                </ng-container>

                                <ng-container matColumnDef="author">
                                    <th mat-header-cell *matHeaderCellDef>Author</th>
                                    <td mat-cell *matCellDef="let pin">{{pin.author}}</td>
                                </ng-container>

                                <ng-container matColumnDef="updated_at">
                                    <th mat-header-cell *matHeaderCellDef>Last Updated</th>
                                    <td mat-cell *matCellDef="let pin">{{pin.updated_at | date}}</td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                </tr>
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell" colspan="9999" style="text-align: center;">
                                        <i>No Pins were found...</i>
                                    </td>
                                </tr>
                            </table>

                            <tfoot>
                                <mat-paginator
                                    [length]="totalRows"
                                    [pageIndex]="currentPage"
                                    [pageSize]="pageSize"
                                    [pageSizeOptions]="pageSizeOptions"
                                    (page)="pinActivityPageChanged($event)"
                                    aria-label="Select page"
                                >
                                </mat-paginator>
                            </tfoot>
                        </ng-container>
                    </ng-container>

                    <ng-template #noPermission>
                        <h4 style="text-align: center;">You do not have permission to view pins.</h4>
                    </ng-template>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="col-12 col-md-4">
            <mat-card class="mt-3" tourAnchor="dashboard.latest-operative-activity">
                <mat-card-title>
                    <h2>Latest Operative Activity</h2>
                </mat-card-title>

                <mat-card-subtitle>Showing the latest operative activity across all sites for the last 7 days.</mat-card-subtitle>

                <mat-card-content>
                    <table mat-table [dataSource]="latestOperativeActivity" class="w-100">
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef>Name</th>
                            <td mat-cell *matCellDef="let opererative">{{opererative.name}}</td>
                        </ng-container>

                        <ng-container matColumnDef="total">
                            <th mat-header-cell *matHeaderCellDef>Total</th>
                            <td mat-cell *matCellDef="let opererative">{{opererative.total}}</td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsLOA"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsLOA;">
                        </tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="9999" style="text-align: center;">
                                <i>No Operatives were found...</i>
                            </td>
                        </tr>

                        <tfoot>
                            <mat-paginator
                                [length]="totalRowsLOA"
                                [pageIndex]="currentPageLOA"
                                [pageSize]="pageSizeLOA"
                                [pageSizeOptions]="pageSizeOptionsLOA"
                                (page)="operativeActivityPageChanged($event)"
                                aria-label="Select page"
                            >
                            </mat-paginator>
                        </tfoot>
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
    </div>

</div>
