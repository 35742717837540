<mat-toolbar color="primary">
    <mat-toolbar-row>
        <button mat-icon-button>
            <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
        </button>

        <img style="width: 13em; margin-left: 1em;" src="assets\images\logo\FSP_Orange_BG.png">

        <!-- <span>{{ title }}</span> -->

        <span class="flex-spacer"></span>

        <button
            class="pill d-none d-md-inline"
            *ngIf="authService.credits < (authService.getTenantSetting('low_credit_warning')?.payload ?? 400) && authService.credits >= 0"
            mat-raised-button
            (click)="purchaseCredits()"
            style="margin-right: 10px; background-color: #ffee58 !important; color: black;"
        >
            <mat-icon style="margin-right: 5px; line-height: 23px; font-size: 20px;">warning</mat-icon> Low Credits
        </button>

        <button class="pill" mat-raised-button (click)="purchaseCredits()" color="primary" style="margin-right: 10px;"><mat-icon style="margin-right: 5px; line-height: 23px;">toll</mat-icon>{{ authService.credits | number:'' }}</button>

        <!-- <button *ngIf="usersService?.user?.tenants.length > 1; else singleTenant" mat-button class="float-end" [matMenuTriggerFor]="tenantMenu">
            <mat-icon>arrow_drop_down</mat-icon>
            {{ authService.tenant.name }}
        </button>

        <mat-menu #tenantMenu="matMenu" *ngIf="usersService?.user?.tenants.length > 1">
            <button *ngFor="let tenant of usersService.user.tenants" mat-menu-item (click)="switchTenant(tenant)">
                <span>{{ tenant.name }}</span>
            </button>
        </mat-menu> -->

        <!-- <ng-template> -->
            <span style="font-family: Roboto, 'Helvetica Neue' sans-serif;
            font-size: 14px;
            font-weight: 500;">{{authService.tenant.name}}</span>
        <!-- </ng-template> -->

        <button mat-button class="float-end" [matMenuTriggerFor]="userMenu">
            <mat-icon>account_circle</mat-icon>
            {{ usersService.user.name }}
        </button>

        <mat-menu #userMenu="matMenu">
            <button mat-menu-item (click)="openUserPreferences()">
                <mat-icon>engineering</mat-icon>
                <span>User Preferences</span>
            </button>

            <mat-divider></mat-divider>

            <button mat-menu-item (click)="logout()">
                <mat-icon>logout</mat-icon>
                <span>Sign Out</span>
            </button>
        </mat-menu>
    </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container>
    <mat-sidenav mode="side" opened #sidenav>
        <mat-nav-list>
            <ng-container *ngFor="let link of links">
                <a
                    mat-list-item
                    *ngIf="link.permission ? (link.permission | hasPermission) : true"
                    routerLink="/{{ link.url }}"
                    routerLinkActive="active"
                >
                    <mat-icon style="margin-right: 5px;">{{ link.icon }}</mat-icon>
                    {{ link.name }}
                </a>
            </ng-container>
        </mat-nav-list>

        <button (click)="throwTestError()" mat-stroked-button color="primary" *ngIf="usersService.user.id === 1">Test Sentry Error</button>
    </mat-sidenav>

    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>

<app-admin-footer></app-admin-footer>
