import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable}              from '@angular/core';
import {Router}                  from '@angular/router';
import Hotjar                    from '@hotjar/browser';
import * as Sentry               from '@sentry/angular';
import {UsersService}            from '@src/app/admin/users/services/users.service';
import {PermissionsService}      from '@src/app/core/services/permissions.service';
import {Walkthrough}             from '@src/app/shared/models/walkthrough.model';
import {BaseService}             from '@src/app/shared/services/base.service';
import {environment}             from '@src/environments/environment';
import {Observable, of}          from 'rxjs';
import {switchMap}               from 'rxjs/operators';
import {StorageService}          from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService extends BaseService
{
    public authenticated: boolean = false;

    constructor(
        private router: Router,
        private httpClient: HttpClient,
        private usersService: UsersService,
        private permissionsService: PermissionsService,
        private storageService: StorageService
    )
    {
        super();
    }

    get accessToken(): string
    {
        return this.storageService.read('accessToken') ?? null;
    }

    set accessToken(token: string)
    {
        this.storageService.write('accessToken', token);
    }

    get refreshToken(): string
    {
        return this.storageService.read('refreshToken') ?? null;
    }

    set refreshToken(token: string)
    {
        this.storageService.write('refreshToken', token);
    }

    get tenant(): any
    {
        return JSON.parse(this.storageService.read('tenant')) ?? null;
    }

    set tenant(tenant: any)
    {
        this.storageService.write('tenant', JSON.stringify(tenant));
    }

    get walkthroughs(): Walkthrough[]
    {
        return JSON.parse(this.storageService.read('walkthroughs')) ?? null;
    }

    set walkthroughs(walkthroughs: Walkthrough[])
    {
        const transformedWalkthroughs = this.transformWalkthroughs(walkthroughs);
        this.storageService.write('walkthroughs', JSON.stringify(transformedWalkthroughs));
    }

    get credits(): number
    {
        return JSON.parse(this.storageService.read('credits')) ?? null;
    }

    set credits(credits: any)
    {
        this.storageService.write('credits', JSON.stringify(credits));
    }

    public login(credentials: { email: string, password: string }): Observable<any>
    {
        if (this.authenticated) {
            this.handleError('User is already authenticated.');
        }

        const headerDict = {
            'X-Skip': 'true',
            'X-Client-Ident': environment.webEnum
        };

        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        return this.httpClient.post(`${this.apiUrl}/login`, credentials, requestOptions).pipe(
            switchMap(async (response: any) => {
                this.authenticateUser(response);

                return of(response);
            })
        );
    }

    public forgotPassword(email: string): Observable<any>
    {
        if (this.authenticated) {
            this.handleError('User is already authenticated.');
        }

        return this.httpClient.post(`${this.apiUrl}/forgot-password`, email).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    public createPassword(url: string, passwordData): Observable<any>
    {
        if (this.authenticated) {
            this.handleError('User is already authenticated.');
        }

        return this.httpClient.post(`${url}`, passwordData).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    public resetPassword(credentials: { token: string, password: string, password_confirmation: string }): Observable<any>
    {
        if (this.authenticated) {
            this.handleError('User is already authenticated.');
        }

        return this.httpClient.post(`${this.apiUrl}/reset-password`, credentials).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    public verifyEmail(code: string, hash: string): Observable<any>
    {
        return this.httpClient.post(`${this.apiUrl}/email/verify`, {
            code: code,
            hash: hash
        }).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    public resendVerificationEmail(email: string): Observable<any>
    {
        if (this.authenticated) {
            this.handleError('User is already authenticated.');
        }

        return this.httpClient.post(`${this.apiUrl}/email/verify/resend`, {email: email}).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    public logout(): Observable<any>
    {
        this.deauthenticateUser();
        return of(true);
    }

    public check(): Observable<boolean>
    {
        if (!this.accessToken) {
            return of(false);
        }

        if (this.authenticated) {
            return of(true);
        }

        if (!this.tokenExpired(this.accessToken)) {
            return of(true);
        }

        return of(false);
    }

    public checkTenant(): Observable<boolean>
    {
        if (this.tenant.billing_address && this.tenant.trading_address) {
            return of(true);
        } else {
            return of(false);
        }
    }

    public getTenantSetting(name: string): any
    {
        return JSON.parse(this.storageService.read('tenant'))?.settings?.find(c => c.name == name) ?? null;
    }

    public register(registerForm): Observable<any>
    {
        return this.httpClient.post(`${this.apiUrl}/register`, registerForm).pipe();
    }

    public authenticateUser(response: any): void
    {
        this.authenticated = true;
        this.usersService.user = response.data.user;
        this.tenant = response.data.primary_tenant;
        this.accessToken = response.data.authorisation.access_token;
        this.refreshToken = response.data.authorisation.refresh_token;
        this.credits = response.data.primary_tenant.credit_balance;
        this.walkthroughs = response.data.walkthroughs;

        this.permissionsService.roles = response.data.user.roles;
        this.permissionsService.permissions = response.data.user.permissions;

        Sentry.setUser({
            id: this.usersService.user.id,
            username: this.usersService.user.name,
            email: this.usersService.user.email,
            ip_address: '{{auto}}'
        });

        if (environment.production) {
            Hotjar.identify(this.usersService.user.id, {
                name: this.usersService.user.name,
                email: this.usersService.user.email
            });
        }
    }

    public deauthenticateUser()
    {
        this.authenticated = false;
        this.usersService.user = null;

        this.storageService.remove('accessToken');
        this.storageService.remove('refreshToken');
        this.storageService.remove('tenant');
        this.storageService.remove('roles');
        this.storageService.remove('permissions');

        Sentry.setUser(null);
        if (environment.production) {
            Hotjar.identify(null, null);
        }

        this.router.navigate(['login']);
    }

    private tokenExpired(token: string)
    {
        const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
        return (Math.floor((new Date).getTime() / 1000)) >= expiry;
    }

    private transformWalkthroughs(walkthroughs: Walkthrough[])
    {
        return walkthroughs.map(walkthrough => {
            walkthrough.items = walkthrough.items.map(item => {
                const {data, ...newItem} = item;

                if (data) {
                    const parsedData = JSON.parse(data);
                    return {...newItem, ...parsedData};
                }

                return newItem;
            });

            return walkthrough;
        });
    }
}
